<template>
  <div class="account">
    <PageSubHeader :pageSubHeader="pageSubHeader" />

    <!-- <main>
      <div id="construction" class="txt-center txt-light_green" style="padding: 15% 0">
        <h1 class="construction_msg">
          <font-awesome-icon icon="hammer" />
          建置中...
          <font-awesome-icon icon="wrench" />
        </h1>
      </div>
    </main> -->

    <main>
      <section id="account_search_extend" class="account_search">
        <div class="w1000">

          <!-- *** 表單主標題 *** -->
          <div class="main_title_box">
            <h3 class="main_title txt-bold">展延申請查詢</h3>
            <div class="img_box">
              <img src="@/statics/img/index/icon_title2.svg" alt="">
            </div>
          </div>

          <!-- *** form area *** -->
          <div class="form_box">
            <form id="form_account_search_extend" action="" method="post">

              <div class="form_description">
                <p>
                  <span id="user_name">{{ realname }}</span> 您好，<br><br>
                  <span class="txt-bold">
                    您的展延申請狀態為：
                    <span class="txt-red">{{ searchStatus }}</span>
                  </span><br>
                  <span v-show="searchMsg">備註：{{ searchMsg }}</span>
                </p>
              </div>
              <div class="form_interactive">
                <Upload
                  class="w100"
                  :class="{ disabled: searchCode !== 9 }"
                  v-model="user.files.upload.file"
                  :item="inputFormat.attachmentUpload"
                  :rules="[
                    val => !!val || '請上傳檔案!',
                  ]"
                  :fileTitle.sync="user.files.upload.title"
                />
              </div>
              <div class="form_submit txt-center">
                <button
                  type="button"
                  class="btn"
                  :class="{ disabled: searchCode !== 9 }"
                  @click="onSubmit"
                >送出</button>
              </div>

            </form>
          </div>

        </div>
      </section>
    </main>
  </div>
</template>

<script>
import PageSubHeader from '@/components/PageSubHeader.vue';
import { AccountSearchSubHeader } from '@/lib/const';
import Upload from '@/components/form/Upload.vue';
// eslint-disable-next-line no-unused-vars
import { specialistInfo, specialistUpload } from '@/lib/http';
import { mapActions } from 'vuex';
// eslint-disable-next-line no-unused-vars
import _ from 'lodash';

export default {
  name: 'AccountSearchProfession',
  data() {
    return {
      ...AccountSearchSubHeader,
      searchCode: null,
      searchStatus: '查無申請資料',
      searchMsg: '',
      // isUploadDisable: true,
      realname: '',
      level: '',
      user: {
        uuid: '',
        files: {
          upload: {
            title: '',
            file: null,
          },
        },
      },
      inputFormat: {
        attachmentUpload: {
          label: '附檔上傳',
          reminder: '格式: zip、doc、docx、pdf',
          accept: '.zip,.doc,.docx,.pdf',
          size: 4 * 1024 * 1024,
          required: true,
        },
      },
    };
  },
  created() {
    this.realname = this.$cookies.get('realname');
    this.level = this.$cookies.get('level');
    if (parseInt(this.level, 10) === 1) {
      specialistInfo({ type: 2 }).then((result) => {
        if (result.data.status) {
          // this.isUploadDisable = false;
          this.searchCode = _.get(result, 'data.result.apply', '');
          this.searchStatus = _.get(result, 'data.result.applyType', '');
          this.searchMsg = _.get(result, 'data.result.msg', '');
          this.user.uuid = _.get(result, 'data.result.uuid', '');
          if (parseInt(this.searchCode, 10) === 1) {
            this.searchStatus = '不通過';
          }
        } else {
          // this.searchStatus = result.data.message;
          this.openModal({
            message: result.data.message,
          });
        }
      });
    } else {
      this.searchStatus = '您尚未成為專科會員';
    }
  },
  methods: {
    ...mapActions([
      'openModal',
    ]),
    onSubmit() {
      const errorList = [];
      this.$children.forEach((item) => {
        if (item.$options.name && item.$options.name.match(/^Form/i)) {
          item.test();
          errorList.push(item.error);
        }
      });
      if (errorList.indexOf(true) === -1) {
        specialistUpload(this.user).then((result) => {
          if (result.data.status) {
            this.openModal({
              message: '資料已送出，請靜待審核結果',
            });
          }
        });
      } else {
        this.openModal({
          message: '必填欄位尚未完成！',
        });
      }
    },
  },
  components: {
    PageSubHeader,
    // eslint-disable-next-line vue/no-unused-components
    Upload,
  },
};
</script>
